import React, { useEffect, useMemo, useState } from "react";
import { useGetEvents } from "../../../api/event/service";
import { EventCard } from "../feedCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Center, createStyles, Text } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";

const Events = ({ user, translation }: any) => {
  const { data, isLoading,isSuccess,fetchNextPage } = useGetEvents();
  const { classes } = useStyles();
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const [arr] = useState([...Array(15)]);
  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );
  console.log("zxcflattenData",flattenedData)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchNextPage();
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef]);
  return (
    <>
      {isLoading && (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2 }}>
          <Masonry className={classes.tab_view} gutter="10px 15px">
            {arr.map((x: any, i: number) => (
              <div
                key={i}
                style={{ height: x }}
                className={classes.skeleten}
              ></div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2 }}>
        <Masonry className={classes.tab_view} gutter="10px 15px">
          {flattenedData?.map((x: any, i: number) => (
            <EventCard
              key={i}
              user={user}
              event={x}
              translation={translation}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      {flattenedData.length === 0 && isSuccess &&
      <Center>
        <Text color="white" fw="bold" fz={20}>{translation.event_page.not_event}</Text>
      </Center>
      }
      <div ref={targetRef} />
    </>
  );
};
const useStyles = createStyles((theme) => ({
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    height:91,
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
}));
export default Events;
