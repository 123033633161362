import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getAppSettings = async () => {
  try {
    const data = await restClient.get("/app-setting");
    return {
      data: data.data.data.attributes.settings,
    };
  } catch (error) {
    console.log("error", error);
  }
};

export const getNotificationEmail = async () => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await restClient.get("/get-subscribers-email-info", config);
    console.log("zxcdata", data);
    return data.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateNotificationSettings = async (data: any) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("zxcda2323ta",data)
  try {
    const _data = await restClient.patch(
      "/update-notification-settings",
      data,
      config
    );
    console.log("zxcdata", _data);
    return _data.data;
  } catch (error) {
    console.log("error", error);
  }
};
