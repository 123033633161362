import React, { useEffect, useState } from "react";
import {
  useGetStatistics,
  useGetStatisticsEvents,
  usePayout,
} from "../../../api/statistics/service";
import WaveAudio from "../waveAudio/WaveAudio";
import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { MdOutlineFileDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import { formatProfileDate } from "../../../utils/formatProfileDate";

const Events = ({
  translation,
  setActiveRight,
  activeRight,
  creatorId,
}: any) => {
  const { data, isLoading, isSuccess }: any = useGetStatisticsEvents(creatorId);
  const {
    payout,
    isLoading: loadingPayout,
    isSuccess: successPayout,
  }: any = usePayout();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [duration, setDuration] = useState(0);
  const { statistics_name } = useParams();
  const [arr] = useState([...Array(6)]);
  const { classes } = useStyles();
  const [payoutModal, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (successPayout) {
      open();
    }
  }, [successPayout]);

  console.log("zxcdata", data);

  useEffect(() => {
    if (!statistics_name) {
      setActiveRight("");
    }
    if (statistics_name === "events") {
      setActiveRight(translation.events);
    }
  }, [statistics_name]);

  return (
    <div>
      {isLoading ? (
        <div className={classes.skeleten} style={{ height: 110 }}></div>
      ) : (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            padding: 10,
            marginBottom: 20,
            borderRadius: 10,
          }}
        >
          <Title color="lightgray">
            {translation.statistics_page.event_analysis}
          </Title>
          <Divider mt={10} color="gray" />
          <Flex justify="space-between" align="center" mt={10}>
            <Flex className={classes.total_area} gap={20}>
              <Flex align="center" gap={5}>
                <MdOutlineRemoveRedEye color="white" size={20} />
                <Text color="white">
                  {translation.statistics_page.total_viewers +
                    data?.data?.totalAuidenceCount}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </div>
      )}

      {isLoading &&
        arr.map((i: number) => (
          <div key={i} className={classes.skeleten}></div>
        ))}
      {data?.data?.response?.map((data: any) => (
        <Flex
          direction="column"
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            padding: 10,
            marginBottom: 20,
            borderRadius: 10,
          }}
        >
          <Flex>
            <Flex ml={10} w="100%" direction="column">
              <Text color="white">{translation.event + ": " + data.title}</Text>
            </Flex>
          </Flex>
          <Divider mt={10} color="gray" />
          <Flex mt={10} direction="row" gap={15} justify="space-between">
            <Text color="white">{formatProfileDate(data?.createdAt)}</Text>
            <Flex align="center" gap={5}>
              <MdOutlineRemoveRedEye color="white" />
              <Text color="white">{data.auidenceCount}</Text>
            </Flex>
          </Flex>
        </Flex>
      ))}
      {data?.data?.response?.length === 0 && isSuccess && (
        <Title align="center" color="gray" mt={10}>
          {translation.statistics_page.have_no_content}
        </Title>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    height: 150,
    marginBottom: 20,
    width: "100%",
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
  total_area: {
    [`@media (max-width: 768px)`]: {
      flexDirection: "column",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
}));
export default Events;
